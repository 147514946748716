import {FuseLoadable} from '@fuse';
import TncRedirect from "../tncRedirect/TncRedirect";

export const TncRedirectConfig = {
    settings: {
        layout: {
            style : 'layout2',
            config: {
                navbar        : {
                    display: false
                },
                toolbar       : {
                    display: false
                },
                footer        : {
                    display: false

                },
                leftSidePanel : {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes  : [
        {
            path     : '/code/tncRedirect',
            component: TncRedirect
        }
    ]
};
