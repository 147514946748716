import {FuseLoadable} from '@fuse';
import {authRoles} from 'auth';
import CTAPage from "./CTAPage";

export const CTAPageConfig = {
    settings: {
        layout: {
            style : 'layout2',
            config: {
                navbar        : {
                    display: false
                },
                toolbar       : {
                    display: false
                },
                footer        : {
                    display: false

                },
                leftSidePanel : {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    //auth    : authRoles.onlyGuest,
    routes  : [
        {
            path     : '/cta/:code',
            component: CTAPage
        }
    ]
};
