import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Card, CardContent, Typography} from '@material-ui/core';
import classNames from 'classnames';
import axios from 'axios/index';
import {FuseAnimate} from '@fuse';
import {API} from "aws-amplify/lib/index";

const styles = theme => ({
    root       : {
        background    : "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
        backgroundSize: 'cover',
        flex          : '1 0 auto',
        '@media print': {
            transform      : 'scale(0.9)',
            transformOrigin: 'top'
        }
    },
    card       : {
        maxWidth         : 1020,
        '@media print': {
            width    : '100%!important',
            boxShadow: 'none'
        }
    },
    cardContent: {},
    divider    : {
        width          : 1,
        backgroundColor: theme.palette.divider,
        height         : 144
    }
});

class TNCPage extends Component {
    state = {
        invoice: {},
        tncData: {}
    };

    componentDidMount()
    {
console.log(this.props.match.params);
        let apiName = 'V5mhwebsite';
        let path = '/v5/tnc/'+this.props.match.params.code;//+'/'+this.props.match.params.keyword;
        let myInit = { // OPTIONAL
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'VDuW0I43yyaBrYL689kdt5wbD7DFdskI4MkHWNhH'
            }, // OPTIONAL
        };
        return API.get(apiName, path, myInit).then(result => {
            console.log("result")
            console.log(result);
            if(result){
                this.setState({tncData: result});
            }else{
                window.location.replace("/code/tncRedirect");
            }

        }).catch(error => {
            console.log(error)

        });


    }

    render()
    {
        const {classes} = this.props;
        const {tncData} = this.state;


        return (
            <div className={classNames(classes.root, "p-0 sm:p-64  print:p-0")}>

                {tncData && (

                    <FuseAnimate animation={{translateY: [0, '100%']}} duration={20}>

                        <Card className={classNames(classes.card, "mx-auto")}>
                            <FuseAnimate animation="transition.expandIn">
                                <div className="flex flex-col items-center justify-center text-center ">
                                    {tncData.logoUrl &&
                                    <img className="lg:max-w-256 m-32" src={tncData.logoUrl} alt="logo"/>
                                    }
                                </div>

                            </FuseAnimate>
                            <CardContent className={classNames(classes.cardContent, " print:p-0")}>
                                <FuseAnimate animation="transition.slideUpIn" delay={100}>
                                    <Typography variant="h3" color="inherit" className="font-light mb-32">
                                        Mobile Terms & Privacy Policy
                                    </Typography>

                                </FuseAnimate>

                                <FuseAnimate animation="transition.slideUpIn" delay={400}>
                                    <Typography variant="h5" color="inherit" className="font-light">
                                        Program Description
                                    </Typography>

                                </FuseAnimate>
                                <FuseAnimate animation="transition.expandIn" delay={900}>
                                    <Typography  color="textSecondary" className="font-medium mb-16">
                                        {tncData.description?tncData.description:"The program sends subscribers alerts, promotions, coupons, and time sensitive deals from "+ tncData.companyName+ "."}
                                    </Typography>

                                </FuseAnimate>
                                <FuseAnimate animation="transition.expandIn" delay={900}>
                                    <Typography  color="textSecondary" className="font-medium mb-16">
                                        To opt-in, Text {tncData.keyword ? tncData.keyword : "'Keyword'"} to {tncData.senderIdAlias?tncData.senderIdAlias:tncData.PK_senderId}. {tncData.recurringMessages ? tncData.recurringMessages : "You will receive recurring messages"}. Msg&data rates may apply.
                                        To opt-out, Text STOP to {tncData.senderIdAlias?tncData.senderIdAlias:tncData.PK_senderId}. An opt-out confirmation message will be sent back to you.
                                        To request support, Text HELP to {tncData.senderIdAlias?tncData.senderIdAlias:tncData.PK_senderId} or contact us at <a href={tncData.supportEmail ? 'mailto:'+tncData.supportEmail : "mailto:support@txtwire.com"}>{tncData.supportEmail ? tncData.supportEmail : "support@txtwire.com"}</a>.
                                        Text messages are sent from an autodialing system.
                                        Participation is not required for the purchase of goods or services.
                                        Subscribers will receive an SMS message if their device does not support MMS.
                                    </Typography>

                                </FuseAnimate>
                                <FuseAnimate animation="transition.slideUpIn" delay={700}>
                                    <Typography variant="h5" color="inherit" className="font-light">
                                        Privacy Policy
                                    </Typography>
                                </FuseAnimate>
                                <FuseAnimate animation="transition.expandIn" delay={900}>
                                    <Typography  color="textSecondary" className="font-medium mb-16">
                                        No mobile information will be shared with third parties/affiliates. Opt-in data and consent is non-transferable to any other parties or affiliates involved in our processes. For all other privacy policy information, please visit: <a href={tncData.privacyPolicyUrl ? tncData.privacyPolicyUrl : 'https://www.txtwire.com/privacy-policy/'}>privacy policy</a>.
                                    </Typography>
                                </FuseAnimate>
                                <FuseAnimate animation="transition.slideUpIn" delay={1100}>
                                    <Typography variant="h5" color="inherit" className="font-light">
                                        Supported Carriers
                                    </Typography>
                                </FuseAnimate>
                                <FuseAnimate delay={1300}>
                                    <Typography  color="textSecondary" className="mb-16">
                                        This program is supported by Alltel, AT&T, Boost, Sprint, T-Mobile®, Verizon Wireless,
                                        Virgin Mobile, MetroPCS, and U.S. Cellular. Products & services are compatible with AT&T handsets. Carriers are not
                                        liable for delayed or undelivered messages.
                                    </Typography>
                                </FuseAnimate>
                            </CardContent>
                        </Card>
                    </FuseAnimate>
                )}
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(TNCPage);
/**

 Use the following elements to add breaks to your pages. This will make sure that the section in between
 these elements will be printed on a new page. The following two elements must be used before and after the
 page content that you want to show as a new page. So, you have to wrap your content with them.

 Elements:
 ---------
 <div className="page-break-after"></div>
 <div className="page-break-before"></div>


 Example:
 --------

 Initial page content!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>

 This is the second page!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>

 This is the third page!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>
 **/