import React from 'react';
import {withStyles, Card, CardContent, Grow, Typography} from '@material-ui/core';
import {darken} from '@material-ui/core/styles/colorManipulator';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        background: 'radial-gradient(' + darken(theme.palette.primary.dark, 0.5) + ' 0%, ' + theme.palette.primary.dark + ' 80%)',
        color     : theme.palette.primary.contrastText
    },
    card       : {
        maxWidth         : 1020,
        '@media print': {
            width    : '100%!important',
            boxShadow: 'none'
        }
    }
});

const TncRedirect = ({classes}) => {
    return (
        <div className={classNames(classes.root, "flex flex-col flex-auto flex-no-shrink items-center justify-center p-32")}>

            <div className="flex flex-col items-center justify-center w-full">

                <Grow in={true}>
                    <Card className="mx-auto">

                        <CardContent className="flex flex-col items-center justify-center text-center p-48">

                            <img className="w-128 m-32" src="assets/images/logos/fuse.svg" alt="logo"/>

                            <Typography variant="subtitle1" className="mb-16">
                                Compliance page was not found.
                            </Typography>

                            <Typography color="textSecondary" className="mb-40">
                                Please enter the url followed by /number.
                                <br/>
                                Example: tnc.mobi/91011
                                <br/>
                                For more support or to add a number
                                <br/>
                                contact <a href="mailto:support@txtwire.com">support@txtwire.com</a>
                            </Typography>

                        </CardContent>
                    </Card>
                </Grow>
            </div>
        </div>
    );
};

export default withStyles(styles, {withTheme: true})(TncRedirect);
