import {FuseLoadable} from '@fuse';
import CtaRedirect from "../ctaRedirect/CtaRedirect";

export const CtaRedirectConfig = {
    settings: {
        layout: {
            style : 'layout2',
            config: {
                navbar        : {
                    display: false
                },
                toolbar       : {
                    display: false
                },
                footer        : {
                    display: false

                },
                leftSidePanel : {
                    display: false
                },
                rightSidePanel: {
                    display: false
                }
            }
        }
    },
    routes  : [
        {
            path     : '/code/ctaRedirect',
            component: CtaRedirect
        }
    ]
};
