import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse/index';
import {CTAPageConfig} from 'main/content/cta/CTAPageConfig';
import {CtaRedirectConfig} from 'main/content/ctaRedirect/CtaRedirectConfig';
import {ExampleConfig} from 'main/content/example/ExampleConfig';
//import {TnCPageConfig} from 'main/content/tnc/TnCPageConfig';
import {TNCPageConfig} from 'main/content/tnc2/TNCPageConfig';
import {TncRedirectConfig} from 'main/content/tncRedirect/TncRedirectConfig';


const routeConfigs = [
    CTAPageConfig,
    CtaRedirectConfig,
    ExampleConfig,
    //TnCPageConfig,
    TncRedirectConfig,
    TNCPageConfig



];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : '/',
        component: () => <Redirect to="/code/tncRedirect"/>
    }

];
