import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FusePageSimple, DemoContent} from '@fuse';
import {API} from "aws-amplify/lib/index";

const styles = theme => ({
    layoutRoot: {}
});
//hey

class Example extends Component {

    getCodes = () => {
        API.get('mhwebsiteV5', '/tncCodes').then((response) => {
            console.log(response);
            return response;

        });
    };
    codes = this.getCodes;
    //console.log(codes);
    render()
    {
        const {classes} = this.props;
        const codes = this.codes;
        return (
            <FusePageSimple
                classes={{
                    root: classes.layoutRoot
                }}
                header={
                    <div className="p-24"><h4>Header</h4></div>
                }
                contentToolbar={
                    <div className="px-24"><h4>Content Toolbar</h4></div>
                }
                content={
                    <div className="p-24">
                        <h4>Content</h4>
                        <br/>
                        {codes}
                        <DemoContent/>
                    </div>
                }
            />
        )
    }
}

export default withStyles(styles, {withTheme: true})(Example);