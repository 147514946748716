/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:a16a43e9-e309-4b3a-9f7f-2e88dbff00e1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_cxD8GXAZv",
    "aws_user_pools_web_client_id": "5ebm600nuoah0hgv6co8b739pj",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "V5mhwebsite",
            "endpoint": "https://vp0arx54fh.execute-api.us-west-2.amazonaws.com/master",
            "region": "us-west-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "V5_DIM_Tnc-master",
            "region": "us-west-2"
        }
    ],
    "aws_content_delivery_bucket": "tnc-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d21lrd7v39j880.cloudfront.net"
};


export default awsmobile;
