import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Card, CardContent, Typography} from '@material-ui/core';
import classNames from 'classnames';
import axios from 'axios/index';
import {FuseAnimate} from '@fuse';
import {API} from "aws-amplify/lib/index";

const styles = theme => ({
    root       : {
        background    : "url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat",
        backgroundSize: 'cover',
        flex          : '1 0 auto',
        '@media print': {
            transform      : 'scale(0.9)',
            transformOrigin: 'top'
        }
    },
    card       : {
        maxWidth         : 1020,
        '@media print': {
            width    : '100%!important',
            boxShadow: 'none'
        }
    },
    cardContent: {},
    divider    : {
        width          : 1,
        backgroundColor: theme.palette.divider,
        height         : 144
    }
});

class CTAPage extends Component {
    state = {
        invoice: null,
        tncData: null
    };

    componentDidMount()
    {
        console.log("cta props")
        console.log(this.props)
console.log(this.props.match.params);
console.log(this.params);
        let apiName = 'V5mhwebsite';
        let path = '/v5/tnc/'+this.props.match.params.code;//+'/'+this.props.match.params.keyword;
        let myInit = { // OPTIONAL
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'VDuW0I43yyaBrYL689kdt5wbD7DFdskI4MkHWNhH'
            }, // OPTIONAL
        };
        return API.get(apiName, path, myInit).then(result => {
            console.log(" cta result")
            console.log(result);
            if(result){
                this.setState({tncData: result});
            }else{
                window.location.replace("/code/ctaRedirect");
            }

        }).catch(error => {
            console.log(error)

        });


    }

    render()
    {
        const {classes} = this.props;
        const {tncData} = this.state;


        return (
            <div className={classNames(classes.root, "p-0 sm:p-64  print:p-0")}>

                {tncData && (

                    <FuseAnimate animation={{translateY: [0, '100%']}} duration={20}>

                        <Card className={classNames(classes.card, "mx-auto")}>
                            <FuseAnimate animation="transition.expandIn">
                                <div className="flex flex-col items-center justify-center text-center ">
                                    <img className="lg:max-w-256 m-32" src={tncData.logoUrl?tncData.logoUrl:"assets/images/logos/fuse.svg"} alt="logo"/>
                                </div>
                            </FuseAnimate>
                            <CardContent className={classNames(classes.cardContent, " print:p-0")}>

                                <div className="mb-64">
                                    <FuseAnimate animation="transition.slideUpIn" delay={400}>

                                        <div className="flex flex-col items-center justify-center text-center ">
                                            <Typography variant="h2" color="inherit" className="font-light">
                                                Text {tncData.keyword ? tncData.keyword : "'Keyword'"} to {tncData.senderIdAlias?tncData.senderIdAlias:tncData.PK_senderId}
                                            </Typography>
                                        </div>
                                    </FuseAnimate>
                                </div>
                                <div className="mb-64">
                                    <FuseAnimate animation="transition.slideUpIn" delay={700}>
                                        <div className="flex flex-col items-center justify-center text-center ">
                                            <Typography variant="h4" color="inherit" className="font-light">
                                                {tncData.ctaDescription ? tncData.ctaDescription : "To receive text notifications from"}
                                            <br/>
                                                {tncData.ctaDescription ? "" :tncData.companyName}
                                            </Typography>
                                        </div>
                                    </FuseAnimate>
                                </div>
                                <div className="mb-32 ">
                                    <FuseAnimate delay={1300}>
                                        <div className="flex flex-col items-center justify-center text-center ">
                                            <Typography  color="textSecondary" className="mb-16">
                                                {tncData.customDisclosures ? tncData.customDisclosures :
                                                "You’ll receive recurring messages. Msg & data rates may apply. Reply STOP to Cancel. Reply HELP for "+
                                                "help. Messages are sent from an autodialing system. Participation is not required for the purchase of "+
                                                "goods or services. For Terms and Privacy policy visit https://tnc.mobi/"+tncData.PK_senderId}

                                            </Typography>
                                        </div>
                                    </FuseAnimate>
                                </div>
                            </CardContent>
                        </Card>
                    </FuseAnimate>
                )}
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(CTAPage);
/**

 Use the following elements to add breaks to your pages. This will make sure that the section in between
 these elements will be printed on a new page. The following two elements must be used before and after the
 page content that you want to show as a new page. So, you have to wrap your content with them.

 Elements:
 ---------
 <div className="page-break-after"></div>
 <div className="page-break-before"></div>


 Example:
 --------

 Initial page content!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>

 This is the second page!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>

 This is the third page!

 <div className="page-break-after"></div>
 <div className="page-break-before"></div>
 **/